
.oa-page {
    .top-banner-wrap {
        width: 100%;
        background: #cb2f3c;
        min-height: 300px;
    }
    .oa-main {
        .top-banner-wrap {
            width: 100%;
            background: #cb2f3c;
            min-height: 300px;
            .top-banner-component {
                display: flex;
                justify-content: space-between;
                .left-top-banner {
                    width: 380px;
                    height: 250px;
                    margin-top: 25px;
                    padding: 18px;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 0;
                    h5 {
                        font-size: 22px;
                        line-height: 22px;
                        color: #fff;
                        margin-bottom: 12px;
                    }
                    div {
                        z-index: 10;
                        .banner-content {
                            font-size: 14px;
                            line-height: 26px;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                        span {
                            font-size: 16px;
                            color: #fcbb0b;
                            float: right;
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                    .left-top-banner-bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 380px;
                        height: 250px;
                        background-image: url('../assets/img/bannerbg.png');
                        background-size: 100% 100%;
                        opacity: 0.4;
                        z-index: -1;
                    }
                }
            }
        }
        .dynamic-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 10px 30px 0;
            margin-top: 23px;
            .dynamic {
                margin-top: 17px;
                margin-bottom: 21px;
                background: url(../assets/img/border1.png);
                background-size: 1px 50%;
                background-repeat: repeat-y;
                background-position: 50% 0%;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 569px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        margin-bottom: 18px;
                        p {
                            position: relative;
                            width: 422px;
                            font-size: 18px;
                            line-height: 18px;
                            padding-left: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            box-sizing: border-box;
                            a {
                                color: #333;
                                cursor: pointer;
                            }
                        }
                        p::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 8px;
                            width: 4px;
                            height: 4px;
                            line-height: 18px;
                            border-radius: 50%;
                            background: #ffac13;
                        }
                        span {
                            display: block;
                            font-size: 18px;
                            width: 112px;
                            text-align: left;
                            margin-left: 10px;
                        }
                    }
                    li:nth-child(2n + 1) {
                        padding-right: 27px;
                    }
                    li:nth-child(2n + 2) {
                        padding-left: 39px;
                    }
                    li:nth-last-child(2) {
                        // margin-bottom: 0;
                    }
                    li:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .article-wrap {
            padding: 10px 30px 0;
            margin: 26px auto;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        }
        .book-wrap {
            padding: 10px 30px 0;
            margin-bottom: 26px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        }
        .source-wrap {
            margin-bottom: 30px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            padding: 10px 30px 26px;
            box-sizing: border-box;
            .com-title {
                border-bottom: none;
                margin-bottom: 10px;
            }
            .source {
                li {
                    width: 1138px;
                    display: flex;
                    align-items: center;
                    padding: 16px 0 15px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    border-bottom: 1px dashed #e6e6e6;
                    span {
                        background: #bd1a2d;
                        width: 26px;
                        height: 26px;
                        line-height: 26px;
                        color: #fff;
                        font-size: 10px;
                        text-align: center;
                        border-radius: 4px;
                        margin-right: 6px;
                    }
                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.pagination-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
}
